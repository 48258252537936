/**
 * Cookies and LocalStorage are required by the application to function correctly.
 * Auth0 uses cookies to store the user's session.
 * We use LocalStorage to store the user's token.
 */
export const clientCookiesDisabled = navigator.cookieEnabled === false

/**
 * localStorage and sessionStorage are used by LocalStorageResource and SecureCache.
 * If a users has cookies disabled, these window objects aren't accessible and will throw an error.
 */
export let storageNotSupported = true
try {
  storageNotSupported = window.localStorage && window.sessionStorage && false
} catch (e) {
  if (process.env.NODE_ENV === 'development') {
    console.log(
      'Storage not supported. Browser likely has cookies disabled or is in private browsing mode.'
    )
  }
}

/**
 * Used to determine if the user should be routed to the error page.
 */
export const shouldShowErrorPage = [clientCookiesDisabled, storageNotSupported].some(Boolean)
