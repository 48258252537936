import { isValid, differenceInYears, parse } from 'date-fns'
import { FormikErrors, FormikValues } from 'formik'
import * as Yup from 'yup'
import api from '~/api'

import { InputProps } from '~/components'
import {
  EMPLOYER_INSURANCE,
  MEDICARE_MEDICAID,
  NO_INSURANCE,
  PRIVATE_INSURANCE,
} from '~/screens/signup/steps/account/utils'
import { JPMC } from '~/constants/SignUp'

export interface InputValidationProps extends InputProps {
  values: FormikValues
  errors: FormikErrors<InputValidationProps['values']>
}
type validWithMessage = [boolean, string]

const REQUIRED_MESSAGE = 'Required'

const NO_EMOJIS_REGEX =
  /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g
export const noEmojis = (value = '') => !NO_EMOJIS_REGEX.test(value)

export const firstNameSchema = Yup.string()
  .required('First name is required')
  .min(2, 'Too short!')
  .max(50, 'Too long!')

export const lastNameSchema = Yup.string()
  .required('Last name is required')
  .min(2, 'Too short!')
  .max(50, 'Too long!')

export const preferredNameSchema = Yup.string().min(0).max(50, 'Too long!')

export const emailSchema = Yup.string()
  .required(REQUIRED_MESSAGE)
  .email('Must be a valid email address')

export const dobSchema = Yup.string()
  .required('Birth date is required')
  .test('Invalid Date', 'Must be a valid date (MM/DD/YYYY)', (value = '') => {
    return value.trim().length === 10 && isValid(parse(value, 'MM/dd/yyyy', new Date()))
  })
  .test('Not Old Enough', 'Must be at least 18 years old', (value = '') => {
    return differenceInYears(new Date(), parse(value, 'MM/dd/yyyy', new Date())) >= 18
  })
  .test('Invalid Date', 'Please enter a valid date', (value = '') => {
    return differenceInYears(new Date(), parse(value, 'MM/dd/yyyy', new Date())) <= 110
  })

export const lastSSNSchema = Yup.string()
  .required(REQUIRED_MESSAGE)
  .test(
    'Invalid Social Security Number',
    'Please enter the last 4 digits of your social security number',
    value => {
      if (!value) return true
      return value.replace(/\D+/g, '').length === 4
    }
  )

export const employeeIdentifierSchema = employer => {
  if (employer === JPMC) {
    return Yup.string()
      .required('Employee SID Number is required')
      .min(7, 'Employee SID Number should be 7 digits')
      .max(7, 'Employee SID Number should be 7 digits')
      .matches(/^[a-zA-Z]{1,1}[0-9]{6,6}$/, 'Please enter a valid SID number')
  } else {
    return Yup.string().required('Employee ID is required').min(1, 'Employee ID is required')
  }
}

export const passwordChecks = (value: string): validWithMessage[] => [
  [value.length >= 8, '8 characters minimum'],
]

export const PreferredLanguageOtherSchema = Yup.string()
  .required('Please tell us your preferred language for care')
  .test(
    'No Special Characters',
    'Preferred language cannot include numbers or special characters.',
    noEmojis
  )
  .min(2, 'Preferred language must be at least 2 characters.')

export const passwordSchema = Yup.string().min(8).required(REQUIRED_MESSAGE)

// TODO: Migrate last uses of old validations and remove this
export const createBooleanValidator =
  (schema: Yup.BaseSchema<any>) =>
  (values: any): validWithMessage => {
    let valid: validWithMessage = [true, '']
    try {
      schema.validateSync(values)
    } catch (e) {
      if (e instanceof Error) {
        valid = [false, e.message]
      } else {
        valid = [false, 'Error']
      }
    }
    return valid
  }

export const validateEmail = createBooleanValidator(emailSchema)

export const sourceTypeSchema = Yup.object()
  .shape({ label: Yup.string(), value: Yup.string() })
  .required('Please select if you have insurance')
  .nullable()
  .test(
    MEDICARE_MEDICAID,
    'Firefly isn’t covered by Medicare or Medicaid yet but we’re working on it',
    value => {
      if (value && value.value !== MEDICARE_MEDICAID) {
        return true
      } else return false
    }
  )
  .test(NO_INSURANCE, 'Insurance coverage is required for Firefly', value => {
    if (value && value.value !== NO_INSURANCE) {
      return true
    } else return false
  })

export const insurancePayerSchema = Yup.object()
  .shape({
    fireflyAccepted: Yup.boolean(),
    name: Yup.string(),
    elationPayerCode: Yup.string(),
    label: Yup.string(),
    value: Yup.string(),
    id: Yup.string() || Yup.number(),
  })
  .required('Please select your insurance provider')
  .nullable()

const regexFFMemberId = /^([FF]{2})?\d{8}$/i
export const validateFFMemberId = (value = '') => regexFFMemberId.test(value)

export const FFMemberIdSchema = Yup.string()
  .required('Member ID Number is required')
  .test(
    'Invalid Member ID Number',
    'Please enter the valid Firefly Health Member ID Number',
    validateFFMemberId
  )

export const nonFFMemberIdSchema = Yup.string()
  .required('Member ID Number is required')
  .test(
    'Invalid Member ID Number',
    'Please enter the correct Member ID Number',
    (value: string = '') => {
      return value.replace(/\s+/g, '').length >= 1
    }
  )
